import { createApp, h, provide } from 'vue'
import App from './App.vue'
import router from './router';
import 'vue3-easy-data-table/dist/style.css';

import { IonicVue } from '@ionic/vue';

import {DefaultApolloClient} from "@vue/apollo-composable";

import {apolloClient} from "@/extensions/apollo/apollo-client";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

// Global styles
import './theme/global.scss'

import boot from "@/boot/firebaseAuthApi";

boot();

export declare let hasuraURL: string;
export declare let hasuraWs: string;
export declare let authUrl: string;
export declare let identityProviderApiKey: string;
export declare let identityProviderAuthDomain: string;
export declare let storage_url: string;

const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },

  render: () => h(App),
})
    .use(IonicVue)
    .use(router);


const script = document.createElement('script')

script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyChFpAAv7mCM3y-wAhjmPYWtjouVS_IEtg&libraries=places'

document.head.appendChild(script)

router.isReady().then(() => {
  app.mount('#app');
});
