import {RouteRecordRaw} from 'vue-router';
import {Roles} from '@/commons/roles';


const routes: RouteRecordRaw[] = [
    {
        path: '/login',
        component: () => import('@/layouts/LoginLayout.vue'),
        children: [
            {
                path: '', component: () => import('@/Pages/login/LoginPage.vue'),
                name: 'login'
            }
        ]
    },
    {
        path: '/profiles',
        component: () => import('@/layouts/ProfilesEnterprisesLayout.vue'),
        meta: {requiredRoles: [Roles.ROLE_ENTERPRISE, Roles.ROLE_GOD]},
        children: [
            {
                path: '', component: () => import('@/Pages/profiles/ProfileEnterprisePage.vue'),
                name: 'profiles'
            }
        ]
    },
    {
        path: '/',
        redirect: '/products',
        component: () => import('@/layouts/SplitPanelLayout.vue'),
        meta: {requiredRoles: [Roles.ROLE_ENTERPRISE, Roles.ROLE_GOD]},
        children: [
            {
                path: 'products', component: () => import('@/Pages/products/ProductsPage.vue'),
                name: 'products'
            },
            {
                path: 'orders', component: () => import('@/Pages/orders/OrdersPage.vue'),
                name: 'orders'
            },
            {
                path: 'order/:id/payments',
                component: () => import('@/Pages/orders/payments/PaymentsSubOrdersPage.vue'),
                name: 'payments',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }
            },
            {
                path: 'shippings', component: () => import('@/Pages/shippings/ShippingsPage.vue'),
                name: 'shippings'
            },
            {
                path: 'settings', component: () => import('@/Pages/settings/SettingsPage.vue'),
                name: 'settings'
            },
            {
                path: 'product/new', component: () => import('@/Pages/products/newProduct/NewProductPage.vue'),
                name: 'new'
            },
            {
                path: 'product/:id',
                component: () => import('@/Pages/products/viewEditProduct/ViewEditProductPage.vue'),
                name: 'viewEditProduct',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }
            },
            {
                path: 'variant/:id',
                component: () => import('@/Pages/products/viewEditProduct/viewEditVariant/ViewEditVariantPage.vue'),
                name: 'viewEditVariant',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }
            },
            {
                path: 'properties', component: () => import('@/Pages/properties/PropertiesPage.vue'),
                name: 'properties'
            },
            {
                path: 'sellers', component: () => import('@/Pages/sellers/SellersPage.vue'),
                name: 'sellers'
            },
            {
                path: 'seller/new', component: () => import('@/Pages/sellers/newSeller/NewSellerComponent.vue'),
                name: 'newSeller'
            },
            {
                path: 'product/modal', component: () => import('@/components/modal/ModalComponent.vue'),
                name: 'modal'
            },
            {
                path: 'applicants', component: () => import('@/Pages/applicant/ApplicantsPage.vue'),
                name: 'applicant'
            },
            {
                path: 'commissions', component: () => import('@/Pages/commissions/CommissionsPage.vue'),
                name: 'commissions'
            },
            {
                path: 'enterprise-admins', component: () => import('@/Pages/god/EnterpriseAdminsPage.vue'),
                name: 'enterprise-admins'
            }
        ]
    },
    // Always leave this as last one,
    // but you can also remove it
    {
        path: '/:catchAll(.*)*',
        redirect: '/'
    }
]

export default routes;
