import { Preferences } from '@capacitor/preferences';

class StorageService {

    async setItem(key: string, value: string) {
        await Preferences.set({key: key, value: value});
    }

    async getItem(key: string) {
        const {value} = await Preferences.get({key: key});
        return value;
    }

    async removeItem(key: string) {
        await Preferences.remove({key: key});
    }

    async clear() {
        await Preferences.clear();
    }

}

export default new StorageService();
